import favicon from '../assets/favicons/favicon_baloise.ico';

export const Config = {
    serviceUrl: 'https://al5-app10.adialive.ch',
    serviceId: 'BALOISE-01',
    documentTitle: 'Baloise Meet',
    theme: 'baloise',

    favicon,

    hideTitleResponsiveSmall: true,
};
