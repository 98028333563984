import favicon from '../assets/favicons/favicon_css.ico';
import { MEETING_TYPES } from '../constants/constants';

export const Config = {
    serviceUrl: 'https://al5-app10.adialive.ch',
    serviceId: 'CSS_Versicherung',
    documentTitle: 'CSS Onlineberatung',
    theme: 'css',

    favicon,

    communicatorType: MEETING_TYPES.PHONE_CONSULTING,
    phoneConsultingAudioVideoDisabled: true,
    phoneConsultingScreenSharingDisabled: true,
};
