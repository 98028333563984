import React, { Component } from 'react';
import { connect } from 'react-redux';
import dateFormat from 'dateformat';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { Checkbox } from 'primereact/checkbox';

import { Config } from '../../config/Config';
import { joinMeeting } from '../meetings/actions';
import { showSettings, hideSettings } from '../deviceSettings/actions';
import { confirmDisclaimer, changeAppState } from './actions';
import {
    APP_STATE,
    MEETING_TYPES,
    RESPONSIVE_MODES,
} from '../../constants/constants';
import { getMeetingInfo } from './actions';
import { getTranslatedString } from '../base/i18n/translations';
import TranslatedString from '../base/i18n/TranslatedString';
import { getBrowserCheckMessage } from '../base/util/helpers';
import ContactForm from './ContactForm';

export class LandingPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            draftMeetingId: '',
            draftParticipantName: '',
            draftEmail: '',
            joinErrorId: null,
            disclaimerConfirmed: false,
            preJoinDisclaimerConfirmed: false,
        };

        this.handleDraftMeetingIdChange = this.handleDraftMeetingIdChange.bind(
            this
        );
        this.handleDraftParticipantNameChange = this.handleDraftParticipantNameChange.bind(
            this
        );
        this.handleDraftEmailChange = this.handleDraftEmailChange.bind(this);
        this.handleNextClick = this.handleNextClick.bind(this);
        this.handleJoinClick = this.handleJoinClick.bind(this);
        this.handleContactFormClick = this.handleContactFormClick.bind(this);
        this._recheckTimeout = null;
    }

    componentWillUnmount() {
        this._clearRecheckTimeout();
    }

    handleDraftMeetingIdChange(e) {
        this.setState({ draftMeetingId: e.target.value.trim() });
    }

    handleDraftParticipantNameChange(e) {
        this.setState({ draftParticipantName: e.target.value });
    }

    handleDraftEmailChange(e) {
        this.setState({ draftEmail: e.target.value });
    }

    handleNextClick(e) {
        e.preventDefault();
        this.props.getMeetingInfo(this.state.draftMeetingId);
    }

    handleContactFormClick(e) {
        e.preventDefault();
        this.props.changeAppState(APP_STATE.CONTACT_FORM);
    }

    validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    handleJoinClick(e) {
        e.preventDefault();
        let name = this.state.draftParticipantName;
        let email = this.state.draftEmail;
        if (this.props.participantInfo) {
            this.props.joinMeeting(this.props.meetingId);
        } else {
            if (
                (!name && !Config.nameInputOptional) ||
                (!email && !Config.hideEmailInput)
            ) {
                this.setState({
                    joinErrorId: 'enterNameAndEmail',
                });
            } else if (!Config.hideEmailInput && !this.validateEmail(email)) {
                this.setState({ joinErrorId: 'invalidEmail' });
            } else {
                if (!name) {
                    // TODO implement better
                    name = 'Gast';
                }
                this.props.joinMeeting(this.props.meetingInfo._id, name, email);
            }
        }
    }

    _startRecheckTimeout(time) {
        this._clearRecheckTimeout();
        this._recheckTimeout = setTimeout(() => {
            this._clearRecheckTimeout();
            this.forceUpdate();
        }, time);
    }

    _clearRecheckTimeout() {
        if (this._recheckTimeout) {
            clearTimeout(this._recheckTimeout);
            this._recheckTimeout = null;
        }
    }

    render() {
        let content = null;
        this._clearRecheckTimeout();

        let errorText;
        if (this.props.errorId) {
            if (this.props.errorNo === 51) {
                errorText = getBrowserCheckMessage(
                    this.props.language,
                    this.props.errorData
                );
            } else {
                errorText = getTranslatedString(
                    this.props.language,
                    this.props.errorId
                );
                if (
                    (this.props.errorId === 'webRtcErrorDefault' ||
                        this.props.errorId === 'alcErrorDefault') &&
                    this.props.errorNo
                ) {
                    errorText += ' (' + this.props.errorNo + ')';
                }
            }
        }

        let translationIdTitle = 'meetingTitle';
        let translationIdMeetingId = 'meetingId';
        let translationIdYourMeeting = 'yourMeeting';
        let translationIdJoin = 'joinMeeting';
        let translationIdYourId = 'yourMeetingId';
        let translationIdEnterId = 'enterMeetingId';
        let translationIdNotReady = 'meetingNotReady';
        let translationIdExpired = 'meetingExpired';
        let translationIdCompleted = 'meetingCompleted';

        if (this.props.type === MEETING_TYPES.WEBINAR) {
            translationIdTitle = 'webinarTitle';
            translationIdMeetingId = 'webinarId';
            translationIdYourMeeting = 'yourWebinar';
            translationIdJoin = 'joinWebinar';
            translationIdYourId = 'yourWebinarId';
            translationIdEnterId = 'enterWebinarId';
            translationIdNotReady = 'webinarNotReady';
            translationIdExpired = 'webinarExpired';
            translationIdCompleted = 'webinarCompleted';
        } else if (this.props.type === MEETING_TYPES.PHONE_CONSULTING) {
            translationIdTitle = 'consultationTitle';
            translationIdMeetingId = 'consultationId';
            translationIdYourMeeting = 'yourConsultation';
            translationIdJoin = 'joinConsultation';
            translationIdYourId = 'yourConsultationId';
            translationIdEnterId = 'enterConsultationId';
            translationIdNotReady = 'consultationNotReady';
            translationIdExpired = 'consultationExpired';
            translationIdCompleted = 'consultationCompleted';
        }

        if (this.props.appState === APP_STATE.ENTER_MEETING_ID) {
            content = (
                <div className="msgBox">
                    <div className="contentPart">
                        <img
                            className="msgIcon"
                            src={Config.landingPageImage}
                            alt={'icon'}
                        />
                        <div className="msgTitle">
                            <TranslatedString id={translationIdYourId} />
                        </div>
                        <div className="msgText">
                            <TranslatedString id={translationIdEnterId} />
                        </div>
                        <form
                            className="userInput"
                            onSubmit={this.handleNextClick}
                        >
                            {this.props.errorId && (
                                <div className="p-col errorMsg">
                                    <Message severity="warn" text={errorText} />
                                </div>
                            )}
                            <div className="p-col inputFields">
                                <span className="p-float-label">
                                    <InputText
                                        id="meetingId"
                                        value={this.state.draftMeetingId}
                                        onChange={
                                            this.handleDraftMeetingIdChange
                                        }
                                    />
                                    <label htmlFor="meetingId">
                                        <TranslatedString
                                            id={translationIdMeetingId}
                                        />
                                    </label>
                                </span>
                            </div>
                            <div className="p-col inputButtons">
                                <Button
                                    label={getTranslatedString(
                                        this.props.language,
                                        'continue'
                                    )}
                                    onClick={this.handleNextClick}
                                    disabled={!this.state.draftMeetingId}
                                />
                            </div>
                            {this.props.enableCommunicatorContactForm && (
                                <React.Fragment>
                                    <div className="msgText">
                                        <TranslatedString id="contactFormText" />
                                    </div>
                                    <div className="p-col inputButtons">
                                        <Button
                                            label={getTranslatedString(
                                                this.props.language,
                                                'contactForm'
                                            )}
                                            onClick={
                                                this.handleContactFormClick
                                            }
                                        />
                                    </div>
                                </React.Fragment>
                            )}
                        </form>
                    </div>
                </div>
            );
        } else if (this.props.appState === APP_STATE.DISPLAY_MEETING_INFO) {
            const date = new Date(this.props.meetingInfo.date);
            const dateMin = this.props.meetingInfo.date - 15 * 60 * 1000; // 15 minutes before start
            const dateMax =
                this.props.meetingInfo.date +
                this.props.meetingInfo.durationInMinutes * 60 * 1000 +
                60 * 60 * 1000; // 60 Minutes after planed end
            const now = Date.now() + this.props.serverTimeDiff;
            // to early
            if (now < dateMin) {
                content = (
                    <div className="msgBox">
                        <div className="contentPart">
                            <img
                                className="msgIcon"
                                src={Config.landingPageImage}
                                alt={'icon'}
                            />
                            <div className="msgTitle">
                                <TranslatedString
                                    id={translationIdYourMeeting}
                                />
                                : {this.props.meetingInfo.title}
                            </div>
                            <div className="msgText">
                                {getTranslatedString(
                                    this.props.language,
                                    translationIdNotReady
                                )
                                    .replace(
                                        '{{date}}',
                                        dateFormat(date, 'dd.mm.yyyy')
                                    )
                                    .replace(
                                        '{{time}}',
                                        dateFormat(date, 'HH:MM')
                                    )}
                            </div>
                        </div>
                    </div>
                );
                const timeCheck = dateMin - now + 1000;
                this._startRecheckTimeout(timeCheck); // todo: use an other approach here.. this is kind of ugly

                // to late
            } else if (now > dateMax) {
                content = (
                    <div className="msgBox">
                        <div className="contentPart">
                            <img
                                className="msgIcon"
                                src={Config.landingPageImage}
                                alt={'icon'}
                            />
                            <div className="msgTitle">
                                <TranslatedString
                                    id={translationIdYourMeeting}
                                />
                                : {this.props.meetingInfo.title}
                            </div>
                            <div className="msgText">
                                {dateFormat(date, 'dd.mm.yyyy HH:MM')}
                                <br />
                                <TranslatedString id={translationIdExpired} />
                            </div>
                        </div>
                    </div>
                );

                // ok
            } else {
                content = (
                    <div className="msgBox">
                        <div className="contentPart">
                            <img
                                className="msgIcon"
                                src={Config.landingPageImage}
                                alt={'icon'}
                            />
                            <div className="msgTitle">
                                <TranslatedString
                                    id={translationIdYourMeeting}
                                />
                                : {this.props.meetingInfo.title}
                            </div>
                            <div className="msgText">
                                {dateFormat(date, 'dd.mm.yyyy HH:MM')}
                            </div>
                            <form
                                className="userInput"
                                onSubmit={this.handleJoinClick}
                            >
                                {this.state.joinErrorId && !this.props.errorId && (
                                    <div className="p-col errorMsg">
                                        <Message
                                            severity="warn"
                                            text={getTranslatedString(
                                                this.props.language,
                                                this.state.joinErrorId
                                            )}
                                        />
                                    </div>
                                )}
                                {this.props.errorId && (
                                    <div className="p-col errorMsg">
                                        <Message
                                            severity="warn"
                                            text={errorText}
                                        />
                                    </div>
                                )}
                                {!this.props.participantInfo && (
                                    <div className="p-col inputFields">
                                        <span className="p-float-label">
                                            <InputText
                                                id="participantName"
                                                value={
                                                    this.state
                                                        .draftParticipantName
                                                }
                                                onChange={
                                                    this
                                                        .handleDraftParticipantNameChange
                                                }
                                            />
                                            <label htmlFor="participantName">
                                                <TranslatedString id="yourName" />
                                            </label>
                                        </span>
                                    </div>
                                )}
                                {!this.props.participantInfo &&
                                    !Config.hideEmailInput && (
                                        <div
                                            className="p-col inputFields"
                                            style={{ marginTop: 8 }}
                                        >
                                            <span className="p-float-label">
                                                <InputText
                                                    id="email"
                                                    value={
                                                        this.state.draftEmail
                                                    }
                                                    onChange={
                                                        this
                                                            .handleDraftEmailChange
                                                    }
                                                />
                                                <label htmlFor="email">
                                                    <TranslatedString id="yourEmail" />
                                                </label>
                                            </span>
                                        </div>
                                    )}
                                {Config.preJoinDisclaimerEnabled && (
                                    <div className="preJoinDisclaimerBox">
                                        <div className="p-col">
                                            {Config.preJoinDisclaimerContent &&
                                                Config.preJoinDisclaimerContent[
                                                    this.props.language
                                                ]}
                                        </div>
                                        <div className="p-col">
                                            <Checkbox
                                                inputId="disclaimer"
                                                onChange={(e) =>
                                                    this.setState({
                                                        preJoinDisclaimerConfirmed:
                                                            e.checked,
                                                    })
                                                }
                                                checked={
                                                    this.state
                                                        .preJoinDisclaimerConfirmed
                                                }
                                            />
                                            <label
                                                htmlFor="disclaimer"
                                                className="p-checkbox-label"
                                            >
                                                {Config.preJoinDisclaimerLabel &&
                                                    Config
                                                        .preJoinDisclaimerLabel[
                                                        this.props.language
                                                    ]}
                                            </label>
                                        </div>
                                    </div>
                                )}
                                <div className="p-col inputButtons">
                                    <Button
                                        label={getTranslatedString(
                                            this.props.language,
                                            translationIdJoin
                                        )}
                                        disabled={
                                            Config.preJoinDisclaimerEnabled &&
                                            !this.state
                                                .preJoinDisclaimerConfirmed
                                        }
                                        onClick={this.handleJoinClick}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                );
            }
        } else if (this.props.appState === APP_STATE.LEFT_MEETING) {
            content = (
                <div className="msgBox">
                    <div className="contentPart">
                        <img
                            className="msgIcon"
                            src={Config.landingPageImage}
                            alt={'icon'}
                        />
                        <div className="msgTitle">
                            <TranslatedString id={translationIdCompleted} />
                        </div>
                        {this.props.errorId && (
                            <div className="p-col errorMsg">
                                <Message severity="warn" text={errorText} />
                            </div>
                        )}
                    </div>
                </div>
            );
        } else if (this.props.appState === APP_STATE.INIT_FAILURE) {
            content = (
                <div className="msgBox">
                    <div className="contentPart">
                        <img
                            className="msgIcon"
                            src={Config.landingPageImage}
                            alt={'icon'}
                        />
                        {this.props.errorId && (
                            <div className="p-col errorMsg">
                                <Message severity="warn" text={errorText} />
                            </div>
                        )}
                    </div>
                </div>
            );
        } else if (this.props.appState === APP_STATE.DISCLAIMER) {
            content = (
                <div className="disclaimerBox">
                    <div className="p-col-12">
                        {Config.disclaimerContent &&
                            Config.disclaimerContent[this.props.language]}
                    </div>
                    <div className="p-col-12">
                        <Checkbox
                            inputId="disclaimer"
                            onChange={(e) =>
                                this.setState({
                                    disclaimerConfirmed: e.checked,
                                })
                            }
                            checked={this.state.disclaimerConfirmed}
                        />
                        <label
                            htmlFor="disclaimer"
                            className="p-checkbox-label"
                        >
                            {Config.disclaimerLabel &&
                                Config.disclaimerLabel[this.props.language]}
                        </label>
                    </div>
                    <div className="p-col-12">
                        <Button
                            label={getTranslatedString(
                                this.props.language,
                                'continue'
                            )}
                            disabled={!this.state.disclaimerConfirmed}
                            onClick={this.props.confirmDisclaimer}
                        />
                    </div>
                </div>
            );
        } else if (this.props.appState === APP_STATE.CONTACT_FORM) {
            content = <ContactForm />;
        }

        const settingsButton =
            this.props.appState !== APP_STATE.LEFT_MEETING &&
            this.props.appState !== APP_STATE.INIT_FAILURE &&
            this.props.appState !== APP_STATE.DISCLAIMER &&
            // TODO: set based on meeting type?
            !Config.phoneConsultingAudioVideoDisabled ? (
                <div className="landingIcon" aria-label="Settings">
                    {this.props.settingsPanelShown ? (
                        <i
                            className="icon-settings toggled"
                            onClick={this.props.hideSettings}
                        />
                    ) : (
                        <i
                            className="icon-settings"
                            onClick={() => this.props.showSettings()}
                        />
                    )}
                </div>
            ) : null;

        return (
            <div className="page">
                <div className="pageHeader">
                    {this.props.logo && (
                        <img
                            src={this.props.logo}
                            className="landingLogo"
                            alt="Logo"
                        />
                    )}
                    {(!Config.hideTitleResponsiveSmall ||
                        this.props.responsiveMode >
                            RESPONSIVE_MODES.MEDIUM) && (
                        <div className="headerTitle">
                            <TranslatedString id={translationIdTitle} />
                        </div>
                    )}
                    {settingsButton}
                </div>
                <div className="msgContainer">{content}</div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    let type = MEETING_TYPES.MEETING;
    if (state.landingPage.meetingInfo && state.landingPage.meetingInfo.type) {
        type = state.landingPage.meetingInfo.type;
    } else if (Config.communicatorType) {
        type = Config.communicatorType;
    }

    let logo;
    if (Config.logoOverride) {
        logo = Config.logoOverride;
    } else if (state.landingPage.publicServiceInfo) {
        logo = state.landingPage.publicServiceInfo.logo;
    }

    return {
        type,
        meetingId: state.landingPage.meetingId,
        appState: state.landingPage.appState,
        meetingInfo: state.landingPage.meetingInfo,
        serverTimeDiff: state.landingPage.serverTimeDiff,
        participantInfo: state.landingPage.participantInfo,
        errorId: state.landingPage.errorId,
        errorNo: state.landingPage.errorNo,
        errorData: state.landingPage.errorData,
        logo,
        enableCommunicatorContactForm:
            state.landingPage.publicServiceInfo &&
            state.landingPage.publicServiceInfo.meetingsSettings
                ? state.landingPage.publicServiceInfo.meetingsSettings
                      .enableCommunicatorContactForm
                : false,
        language: state.base.i18n.language,
        responsiveMode: state.base.common.responsiveMode,
    };
};

const mapDispatchToProps = {
    joinMeeting,
    getMeetingInfo,
    showSettings,
    hideSettings,
    confirmDisclaimer,
    changeAppState,
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
