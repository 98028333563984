import Connector from '@adialive/connector';
import { Config } from '../config/Config';

import {
    alcInitialized,
    alcJoinMeetingSuccess,
    alcClientInfoUpdate,
    alcMeetingParticipantsUpdate,
    alcMeetingInfoUpdate,
    alcError,
    alcLeaveMeetingSuccess,
    alcKicked,
    alcUpdateServerTimeDiff,
    alcReconnecting,
    alcSessionRestored,
    alcMeetingCompleted,
    alcChangeSpeakerPermission,
    showSpeakerConfirmation,
    hideSpeakerConfirmation,
} from '../features/meetings/actions';
import {
    ALC_INIT,
    ALC_JOIN_MEETING,
    ALC_ADVISER_AUTH,
    ALC_SET_CURRENT_APP,
    ALC_SET_EDIT_PERMISSIONS,
    ALC_CHANGE_ACTIVE_USER,
    ALC_LEAVE_MEETING,
    ALC_START_RECORDING,
    ALC_STOP_RECORDING,
    ALC_CLOSE,
    WEBRTC_START_SCREENSHARE_SUCCESS,
    WEBRTC_STOP_SCREENSHARE_SUCCESS,
    ALC_MUTE_PARTICIPANT,
    ALC_REQUEST_SPEAKER_PERMISSION,
    REVOKE_SPEAKER_PERMISSION,
    ALC_CONFIRM_RECORDING,
} from '../features/meetings/actionTypes';
import { ALC_GET_MEETING_INFO } from '../features/landingPage/actionTypes';
import {
    WEBRTC_CHANGE_CAM_SUCCESS,
    WEBRTC_CHANGE_COMMUNICATION_MODE_SUCCESS,
    WEBRTC_CHANGE_MIC_SUCCESS,
    WEBRTC_CHANGE_RES_SUCCESS,
    WEBRTC_MUTE_AUDIO,
    WEBRTC_MUTE_VIDEO,
} from '../features/deviceSettings/actionTypes';
import {
    webRtcChangeCommunicationMode,
    webRtcMuteAudio,
} from '../features/deviceSettings/actions';
import { alcGetMeetingInfoSuccess } from '../features/landingPage/actions';
import { COMMUNICATION_MODES } from '../constants/constants';
import { showMessage } from '../features/base/messages/actions';
import { log } from '../features/base/util/helpers';

export const createAlcMiddleware = () => {
    function calculateDeviceInfo(
        communicationMode,
        audioMuted,
        videoMuted,
        screenShareActive
    ) {
        const deviceInfo = {
            audio: false,
            video: false,
            screen: false,
        };
        switch (communicationMode) {
            case COMMUNICATION_MODES.AUDIO: {
                deviceInfo.audio = true;
                break;
            }
            case COMMUNICATION_MODES.VIDEO: {
                deviceInfo.video = true;
                break;
            }
            case COMMUNICATION_MODES.BOTH: {
                deviceInfo.audio = true;
                deviceInfo.video = true;
                break;
            }
            default: {
                break;
            }
        }
        if (audioMuted) {
            deviceInfo.audio = false;
        }
        if (videoMuted) {
            deviceInfo.video = false;
        }
        if (screenShareActive) {
            deviceInfo.screen = true;
        }
        return deviceInfo;
    }

    let alc;

    return (store) => (next) => (action) => {
        const alcMeetingJoined = store.getState().meetings.alcMeetingJoined;

        switch (action.type) {
            case ALC_INIT: {
                if (alc) {
                    alc.close();
                }

                const serviceUrl = Config.serviceUrl;
                const serviceId = action.serviceId;

                const notificationHandler = function (msg) {
                    log.debug(msg);
                    if (msg.hasOwnProperty('serverTime')) {
                        store.dispatch(
                            alcUpdateServerTimeDiff(msg.serverTime - Date.now())
                        );
                    }
                    switch (msg.type) {
                        case 'initialize':
                            store.dispatch(alcInitialized());
                            break;
                        case 'reconnecting':
                            store.dispatch(alcReconnecting());
                            break;
                        case 'sessionRestored':
                            store.dispatch(alcSessionRestored());
                            break;
                        case 'clientInfoUpdate':
                            store.dispatch(alcClientInfoUpdate(msg.data));
                            break;
                        case 'joinedMeeting':
                            store.dispatch(
                                alcJoinMeetingSuccess(
                                    msg.data.joinOptions,
                                    msg.data.meetingInfo,
                                    msg.data.saAuth
                                )
                            );
                            break;
                        case 'leftMeeting': {
                            if (msg.data === 'kicked') {
                                store.dispatch(alcKicked());
                            } else if (msg.data === 'completed') {
                                store.dispatch(alcMeetingCompleted());
                            } else {
                                store.dispatch(alcLeaveMeetingSuccess());
                            }
                            break;
                        }
                        case 'meetingInfo': {
                            store.dispatch(
                                alcGetMeetingInfoSuccess(
                                    msg.data.meetingInfo,
                                    msg.data.participantInfo,
                                    msg.serverTime
                                )
                            );
                            break;
                        }
                        case 'meetingInfoUpdate':
                            store.dispatch(alcMeetingInfoUpdate(msg.data));
                            break;
                        case 'meetingParticipantsUpdate':
                            store.dispatch(
                                alcMeetingParticipantsUpdate(msg.data)
                            );
                            break;
                        case 'changeDeviceUsage':
                            if (msg.data.audio === false) {
                                store.dispatch(webRtcMuteAudio(true));
                                store.dispatch(
                                    showMessage({
                                        contentId: 'msgRemoteMute',
                                        type: 'info',
                                    })
                                );
                            }
                            break;
                        case 'changeSpeakerPermission': {
                            store.dispatch(
                                alcChangeSpeakerPermission(msg.data.permission)
                            );
                            const state = store.getState();
                            if (
                                msg.data.permission &&
                                !state.meetings.clientInfo.speakerPermission
                            ) {
                                store.dispatch(showSpeakerConfirmation());
                            }
                            if (
                                !msg.data.permission &&
                                store.getState().deviceSettings !==
                                    COMMUNICATION_MODES.NONE
                            ) {
                                if (state.meetings.speakerConfirmationVisible) {
                                    store.dispatch(hideSpeakerConfirmation());
                                }
                                store.dispatch(
                                    webRtcChangeCommunicationMode(
                                        COMMUNICATION_MODES.NONE
                                    )
                                );
                            }
                            break;
                        }
                        case 'serviceError':
                            store.dispatch(
                                alcError(msg.data.context, msg.data.error)
                            );
                            break;
                        default:
                            break;
                    }
                };

                alc = new Connector({
                    serviceUrl,
                    serviceId,
                    notificationHandler,
                });

                alc.init();

                return next(action);
            }
            case ALC_GET_MEETING_INFO: {
                alc.getMeetingInfo(action.meetingId);
                return next(action);
            }
            case ALC_JOIN_MEETING: {
                const deviceInfo = calculateDeviceInfo(
                    action.communicationMode,
                    false,
                    false,
                    false
                );
                let participantInfo;
                if (action.participantName) {
                    participantInfo = {
                        name: action.participantName,
                        email: action.email,
                    };
                }
                alc.joinMeeting(
                    action.meetingId,
                    participantInfo,
                    deviceInfo,
                    false,
                    action.recordingConfirmed
                );
                return next(action);
            }
            case ALC_LEAVE_MEETING: {
                if (alcMeetingJoined) {
                    alc.leaveMeeting();
                }
                return next(action);
            }
            case ALC_ADVISER_AUTH: {
                alc.adviserAuth(action.authToken);
                return next(action);
            }
            case ALC_SET_CURRENT_APP: {
                if (alcMeetingJoined) {
                    alc.setCurrentApp(action.sharedApplicationId);
                }
                return next(action);
            }
            case ALC_SET_EDIT_PERMISSIONS: {
                if (alcMeetingJoined) {
                    alc.setEditPermissions(
                        action.userId,
                        action.editPermissions
                    );
                }
                return next(action);
            }
            case ALC_CHANGE_ACTIVE_USER: {
                if (alcMeetingJoined) {
                    alc.setCurrentActiveUser(action.userId);
                }
                return next(action);
            }
            case ALC_START_RECORDING: {
                if (alcMeetingJoined) {
                    alc.startRecording();
                }
                return next(action);
            }
            case ALC_STOP_RECORDING: {
                if (alcMeetingJoined) {
                    alc.stopRecording();
                }
                return next(action);
            }
            case ALC_MUTE_PARTICIPANT: {
                if (alcMeetingJoined) {
                    alc.setDeviceUsage(action.userId, { audio: false });
                }
                return next(action);
            }
            case ALC_REQUEST_SPEAKER_PERMISSION: {
                if (alcMeetingJoined) {
                    alc.requestSpeakerPermission(action.isRequesting);
                }
                return next(action);
            }
            case ALC_CLOSE: {
                if (alc) {
                    alc.close();
                }
                return next(action);
            }
            case WEBRTC_MUTE_AUDIO: {
                if (alcMeetingJoined) {
                    const meetingsState = store.getState().meetings;
                    const deviceSettingsState = store.getState().deviceSettings;
                    const deviceInfo = calculateDeviceInfo(
                        deviceSettingsState.communicationMode,
                        action.shouldMute,
                        deviceSettingsState.currentSettings.videoMuted,
                        !!meetingsState.ownScreenShareView
                    );
                    alc.updateDeviceInfo(deviceInfo);
                }
                return next(action);
            }
            case WEBRTC_MUTE_VIDEO: {
                if (alcMeetingJoined) {
                    const meetingsState = store.getState().meetings;
                    const deviceSettingsState = store.getState().deviceSettings;
                    const deviceInfo = calculateDeviceInfo(
                        deviceSettingsState.communicationMode,
                        deviceSettingsState.currentSettings.audioMuted,
                        action.shouldMute,
                        !!meetingsState.ownScreenShareView
                    );
                    alc.updateDeviceInfo(deviceInfo);
                }
                return next(action);
            }
            case WEBRTC_START_SCREENSHARE_SUCCESS: {
                if (alcMeetingJoined) {
                    const deviceSettingsState = store.getState().deviceSettings;
                    const deviceInfo = calculateDeviceInfo(
                        deviceSettingsState.communicationMode,
                        deviceSettingsState.currentSettings.audioMuted,
                        deviceSettingsState.currentSettings.videoMuted,
                        true
                    );
                    alc.updateDeviceInfo(deviceInfo);
                }
                return next(action);
            }
            case WEBRTC_STOP_SCREENSHARE_SUCCESS: {
                if (alcMeetingJoined) {
                    const deviceSettingsState = store.getState().deviceSettings;
                    const deviceInfo = calculateDeviceInfo(
                        deviceSettingsState.communicationMode,
                        deviceSettingsState.currentSettings.audioMuted,
                        deviceSettingsState.currentSettings.videoMuted,
                        false
                    );
                    alc.updateDeviceInfo(deviceInfo);
                }
                return next(action);
            }
            case WEBRTC_CHANGE_CAM_SUCCESS:
            case WEBRTC_CHANGE_MIC_SUCCESS:
            case WEBRTC_CHANGE_RES_SUCCESS: {
                if (alcMeetingJoined) {
                    const deviceInfo = calculateDeviceInfo(
                        store.getState().deviceSettings.communicationMode,
                        action.newSettings.audioMuted,
                        action.newSettings.videoMuted,
                        !!store.getState().meetings.ownScreenShareView
                    );
                    alc.updateDeviceInfo(deviceInfo);
                }
                return next(action);
            }
            case WEBRTC_CHANGE_COMMUNICATION_MODE_SUCCESS: {
                if (alcMeetingJoined) {
                    const deviceInfo = calculateDeviceInfo(
                        action.communicationMode,
                        action.currentSettings.audioMuted,
                        action.currentSettings.videoMuted,
                        !!store.getState().meetings.ownScreenShareView
                    );
                    alc.updateDeviceInfo(deviceInfo);
                }
                return next(action);
            }
            case REVOKE_SPEAKER_PERMISSION: {
                if (alcMeetingJoined) {
                    alc.setSpeakerPermission(
                        store.getState().meetings.clientInfo.id,
                        false
                    );
                }
                return next(action);
            }
            case ALC_CONFIRM_RECORDING: {
                if (alcMeetingJoined) {
                    alc.confirmRecording(action.confirmed);
                }
                return next(action);
            }
            default:
                return next(action);
        }
    };
};
