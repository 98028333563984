import React, { Component } from 'react';
import classNames from 'classnames';

import { connect } from 'react-redux';
import MeetingTime from './MeetingTime';
import { RESPONSIVE_MODES } from '../../constants/constants';
import { Config } from '../../config/Config';

export class MeetingTopbar extends Component {
    render() {
        return (
            <div
                className={classNames('meeting-topbar', {
                    visible: this.props.controlsVisible,
                })}
            >
                <div className="meeting-topbar-background" />
                <div className="meeting-topbar-content">
                    <div className="button-group-left">
                        {this.props.logo &&
                            this.props.responsiveMode >
                                RESPONSIVE_MODES.MEDIUM && (
                                <img
                                    src={this.props.logo}
                                    className="serviceLogo"
                                    alt="logo"
                                />
                            )}
                    </div>
                    <div className="button-group-center">
                        {this.props.responsiveMode > RESPONSIVE_MODES.SMALL &&
                            this.props.meetingInfo.title}
                        {this.props.meetingInfo.startTime && (
                            <MeetingTime
                                startTime={this.props.meetingInfo.startTime}
                            />
                        )}
                    </div>
                    <div className={'button-group-right'} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    let logo;
    if (Config.logoOverride) {
        logo = Config.logoOverride;
    } else if (state.landingPage.publicServiceInfo) {
        logo = state.landingPage.publicServiceInfo.logo;
    }

    return {
        meetingInfo: state.meetings.meetingInfo,
        controlsVisible: state.meetings.controlsVisible,
        responsiveMode: state.base.common.responsiveMode,
        logo,
    };
};

export default connect(mapStateToProps, undefined)(MeetingTopbar);
